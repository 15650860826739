<template>
  <div class="wrapper">
    <menu-ver type="superuser" name="Superuser" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="categories && categories.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.categories.nameEsp") }}</th>
            <th scope="col">{{ $t("maintenance.categories.nameEng") }}</th>
            <th scope="col">{{ $t("maintenance.categories.color") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in categories" :key="item.cate_id">
            <td>{{ item.cate_nombre_ESP }}</td>
            <td>{{ item.cate_nombre_ENG }}</td>
            <td>{{ item.cate_color }}</td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.categories.addTitle", { name: `${categoryId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.categories.nameEsp`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormRol"
                v-model.trim="cate_nombre_esp"
                :placeholder="$t(`maintenance.categories.nameEsp`) + ' *'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.categories.nameEng`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName2"
                v-model.trim="cate_nombre_eng"
                :placeholder="$t(`maintenance.categories.nameEng`)"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.categories.color`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName3"
                v-model.trim="cate_color"
                :placeholder="$t(`maintenance.categories.color`)"
                maxlength="50"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addCategory"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.categories.deleteTitle", { name: `${categoryId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.categories.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteCategory"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.categories.editTitle", { name: `${categoryId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.categories.nameEsp`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormRol"
                v-model.trim="cate_nombre_esp"
                :placeholder="$t(`maintenance.categories.nameEsp`) + ' *'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.categories.nameEng`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName2"
                v-model.trim="cate_nombre_eng"
                :placeholder="$t(`maintenance.categories.nameEng`)"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.categories.color`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName3"
                v-model.trim="cate_color"
                :placeholder="$t(`maintenance.categories.color`)"
                maxlength="50"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editCategory"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations,mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      categories: [],
      category: {
      },
      cate_nombre_esp: "",
      cate_nombre_eng: "",
      cate_color: "",
      cate_id: "",
      categoryId: "",
      result: "",
      action: 0,
      formError: false,
      loading: false,
    };
  },
  created() {
    this.getCategories();
    this.changeBreadCrumb(50);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor"])
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getCategories() {
      try {
        const res = await axios.post(
        `${this.servidor}api/op.asp?tp=categoria&ac=listado`
      );
      console.log("list",res);
      if (res.data) {
        this.categories = res.data.data;
      }
      } catch (error) {
        console.error(error);
      }      
    },
    async addCategory() {
      if (this.validateForm()) {
        try {
          this.loading = true;
          const category = {
            cate_nombre_ESP: this.cate_nombre_esp.toUpperCase(),
            cate_nombre_ENG: this.cate_nombre_eng.toUpperCase(),
            cate_color: this.cate_color.toUpperCase(),
          };
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=categoria&ac=grabar`,
            category
          );
          console.log("env",res);
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    async editCategory() {
      if (this.validateForm()) {
        try {
          this.loading = true;
          const category = {
            cate_id: this.cate_id,
            cate_nombre_ESP: this.cate_nombre_esp.toUpperCase(),
            cate_nombre_ENG: this.cate_nombre_eng.toUpperCase(),
            cate_color: this.cate_color.toUpperCase(),
          };          
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=categoria&ac=actualizar`,
            category
          );
          console.log("end",res);
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    async deleteCategory() {
      try {
        const borrado = {
          cate_id: this.cate_id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=categoria&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.categoryId = event[0];
    },
    validateForm() {
      if (this.cate_nombre_esp === "") {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.cate_nombre_esp = "";
      this.cate_nombre_eng = "";
      this.cate_color = "";
      this.loading = false;
      this.formError = false;
      this.getCategories();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.category = item;
        this.cate_id = item.cate_id;
        this.cate_nombre_esp = item.cate_nombre_ESP;
        this.cate_nombre_eng = item.cate_nombre_ENG;
        this.cate_color = item.cate_color;
        return;
      }
      if (item.cate_id) {
        this.cate_id = item.cate_id;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}
</style>